import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { Button } from 'primereact/button';
import axiosApi from '../axiosApi';
import { Word } from '../words/wordListSlice';
import { AttemptDto } from './AttemptDto';

const debouncedSave = _.debounce(async (dto: AttemptDto) => {
  await saveUserTranslation(dto);
}, 5000);

const saveUserTranslation = async (dto: AttemptDto) => {
  if (dto) {
    await axiosApi.post<AttemptDto>(`/api/words1/SaveAttempt`, dto);
    //console.log('Saved');
  }
};

const AttemptPage: React.FC = () => {
  const [id, setId] = useState<string | undefined>();
  const [wordId, setWordId] = useState<string | undefined>();
  const [attempt, setAttempt] = useState<AttemptDto | undefined>();

  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const location = useLocation();

  useEffect(() => {
    const initialize = async () => {
      const attemptId = params.id;
      const query = new URLSearchParams(location.search);
      const wordIdFromQuery = query.get('wordId');
      setWordId(wordIdFromQuery || undefined);

      if (!attemptId || attemptId === '0') {
        const response = await axiosApi.post<AttemptDto>(addNullableQueryParam(`/api/words1/GetAttempt?stub=1`, 'wordId', wordIdFromQuery || ''));
        const newAttempt = response.data;
        setAttempt(newAttempt);
        const newUrl = location.pathname.replace('/0', `/${newAttempt.id}`);
        navigate(newUrl, { replace: true });
        setId(newAttempt.id);
      } else {
        const response = await axiosApi.post<AttemptDto>(addNullableQueryParam(`/api/words1/GetAttempt?attemptId=${attemptId}`, 'wordId', wordIdFromQuery || ''));
        setAttempt(response.data);
        setId(attemptId);
      }
    };

    initialize();
  }, [params.id, location.search]);

  const addNullableQueryParam = (url: string, paramName: string, paramValue?: string) => {
    return paramValue ? `${url}&${paramName}=${paramValue}` : url;
  };

  const dismiss = async () => {
    await axiosApi.post<AttemptDto>(`/api/words1/Dismiss/${id}`, {});
    goPractice();
  };

  const verify = async () => {
    if (!attempt?.userTranslation) {
      alert('Nothing to evaluate. Please provide translation.');
      return;
    }

    const response = await axiosApi.post<AttemptDto>(`/api/words1/VerifyAttempt`, attempt);
    setAttempt(response.data);
  };

  const ask = async () => {
    if (!_.last(attempt?.chatMessages)?.content) {
      alert('Your question seems to be empty.');
      return;
    }

    const response = await axiosApi.post<AttemptDto>(`/api/words1/Ask`, attempt);
    setAttempt({ ...attempt!, chatMessages: response.data.chatMessages });
  };

  const goPractice = async () => {
    const guid = await axiosApi.get<string>(`/api/words1/GetNotFinishedAttemptId`);
    //navigate('/', { replace: true }); // Reset current route

    if (wordId) {
      navigate(`/attempts/0?wordId=${wordId}`);
    } else if (guid.data) {
      navigate(`/attempts/${guid.data}`);
    } else {
      navigate(`/attempts/0`);
    }
  };



  return (
    <div className='grid'>
      <div className='col-12'>
        <div style={{ fontSize: '32px' }}>Practice with AI</div>
      </div>
      <div className="grid col-12 nopad" style={{ justifyContent: 'center' }}>
        <div className="grid col-12" style={{ maxWidth: '480px' }}>
          <div className="col-12">
            <label className="grid-label">Translate the sentence:</label>
            <textarea
              name="word1"
              lang="pt-BR"
              value={attempt?.sentence || ''}
              onChange={(e) => setAttempt({ ...attempt!, sentence: e.target.value })}
              className="grid-input relative z-1 p-2"
            ></textarea>
          </div>
          <div className="col-12">
            <label className="grid-label">Your translation:</label>
            <textarea name="word2" lang="pt-BR" spellCheck={false} className="grid-input relative z-1 p-2"
              value={attempt?.userTranslation || ''}
              onChange={(e) => {
                const changed = { ...attempt!, userTranslation: e.target.value };
                setAttempt(changed);
                debouncedSave(changed);
              }
              }
            ></textarea>
          </div>
          {attempt?.fullGptEvaluation && <div className='col-12 light-behind-2'>
            <label className="grid-label">Words to practice:</label>
            <div
              style={{ border: '1px solid gray', padding: '0px', borderRadius: '8px', overflow: 'hidden' }}
              className="grid-input relative z-1 p-2 bg-color-2">
              <table className="w-full" style={{ padding: '4px' }}>
                <tbody>
                  <tr>
                    <td>                </td>
                    <td className='ps-4 text-center'>Rank</td>
                    <td className='ps-4 text-center'>Score</td>
                  </tr>
                  {attempt?.words.map((word, i) => (
                    <tr key={i}>
                      <td>{word.word?.value || ''}</td>
                      <td className='text-center'>{word.word.rank ?? '0'}</td>
                      <td className='text-center'>
                        {word.score && <span>
                          {(word.score > 0 ? ' +' : ' ') + word.score}
                        </span>}
                      </td>
                    </tr>
                  ))}
                  <tr>

                  </tr>
                </tbody>
              </table>
            </div>
          </div>}
          {attempt?.parsed?.aiComment &&
            <>
              <div className="col-12">
                <label className="grid-label">Evaluation:</label>
                <div className='grid-input p-2' style={{ border: '1px solid gray', borderRadius: '8px', padding: '4px' }}>
                  {attempt?.parsed?.aiComment}
                </div>
              </div>
              {attempt.chatMessages.map((cm, i) => (
                <div className="col-12" key={i}>
                  <label className="grid-label">{cm.role == 'user' ? 'Your message' : 'Assistant'}:</label>
                  {(i != attempt.chatMessages.length - 1) &&
                    <div className='p2' style={{ border: '1px solid gray', borderRadius: '8px', padding: '4px' }}>
                      {cm.content}
                    </div>
                  }
                  {(i == attempt.chatMessages.length - 1) &&
                    <textarea lang="pt-BR" spellCheck={false} className="grid-input p-2"
                      value={cm.content || ''}
                      onChange={(e) => {
                        const changed = {
                          ...attempt!,
                          chatMessages: attempt!.chatMessages.map((msg, index) =>
                            index === i ? { ...msg, content: e.target.value } : msg
                          )
                        };
                        setAttempt(changed);
                        debouncedSave(changed);
                      }}
                    ></textarea>
                  }
                </div>
              ))}
              <div className="col-12">
                {!!attempt?.chatMessages?.length && <Button className="btn-type-4 w-full"
                  onClick={ask}
                >Ask</Button>}
              </div>
            </>
          }
          {!attempt?.fullGptEvaluation && <div className="col-12">
            <Button className="btn-type-4 w-full" onClick={verify} >Check</Button>
          </div>
          }
          {attempt?.fullGptEvaluation && <div className="col-12">
            <Button className="btn-type-4 w-full" onClick={goPractice} >Next Exercise</Button>
          </div>
          }
          {!attempt?.fullGptEvaluation && <div className="col-12">
            <Button className="btn-type-4 w-full" onClick={dismiss} >Regenerate</Button>
          </div>
          }


        </div>
      </div>



    </div>
  );
};

export default AttemptPage;
