'use client';

import { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as css from './courseListSlice';
import { RootState, useAppDispatch } from '../store';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useNavigate } from 'react-router-dom';


export default function WordsPage() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const courses = useSelector(css.selectCourses);
  const totalRecords = useSelector(css.selectTotalRecords);
  const first = useSelector(css.selectFirst);
  const rows = useSelector(css.selectRows);

  useEffect(() => {
    dispatch(css.fetchItems({ first, rows }));
  }, [dispatch, first, rows]);

  const handleNext = () => {
    if (isLastPage()) return;
    dispatch(css.slice.actions.setFirst(first + rows));
  };

  const handlePrev = () => {
    if (first > 0) {
      dispatch(css.slice.actions.setFirst(first - rows));
    }
  };

  const handleStart = () => {
    dispatch(css.slice.actions.setFirst(0));
  };

  const handleEnd = () => {
    dispatch(css.slice.actions.setFirst(Math.floor(totalRecords / rows) * rows));
  };

  const isLastPage = () => {
    return (first + rows) > totalRecords;
  };

  const isFirstPage = () => {
    return first === 0;
  };

  const onPageChange = (event: any) => {
    dispatch(css.fetchItems({ first, rows }));
  };

  const onEdit = async (id: string) => {
    await navigate(`/courses/${id}`);
  };

  return (
    <div className='grid'>
      <div className="grid col-12 w-full flex align-items-center justify-content-between">
        <div className="col-12 md:col-6 flex justify-content-start">
          <div className="flex">
            <span style={{ fontSize: '32px' }}>Courses</span>
          </div>
        </div>
      </div>

      <div className="col-12 ">
        <div className="table-wrap">
          <DataTable value={courses} dataKey="id" paginator={false} rows={10} lazy={true}
            first={first} onPage={onPageChange}
            totalRecords={totalRecords}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          >
            <Column field="languageToLearn" header="Language To Learn" body={(course: css.CourseDto) => (
              <div onClick={() => onEdit(course.id)} style={{ cursor: 'pointer', width: '100%', height: '100%' }}>
                {course.languageToLearn}
              </div>
            )} />
            <Column field="languageOriginal" header="Original Language" body={(course: css.CourseDto) => (
              <div onClick={() => onEdit(course.id)} style={{ cursor: 'pointer', width: '100%', height: '100%' }}>
                {course.languageOriginal}
              </div>
            )} />
            <Column field="description" header="Label" body={(course: css.CourseDto) => (
              <div onClick={() => onEdit(course.id)} style={{ cursor: 'pointer', width: '100%', height: '100%' }}>
                {course.description}
              </div>
            )} />
          </DataTable>
        </div>
        <div className="flex col-12 justify-content-between">
          <div className="flex">
          </div>
          <div className="flex">
            <Button className='flex p-button-text' type='button' icon="pi pi-angle-double-left" text disabled={isFirstPage()}
              onClick={(e) => {
                handleStart();
              }}></Button>
            <Button className='flex p-button-text' type='button' icon="pi pi-chevron-left" disabled={isFirstPage()}
              onClick={(e) => {
                handlePrev();
              }}></Button>
            <Button className='flex p-button-text' type='button' icon="pi pi-chevron-right" disabled={isLastPage()}
              onClick={(e) => {
                handleNext();
              }}></Button>
            <Button className='flex p-button-text' type='button' icon="pi pi-angle-double-right" disabled={isLastPage()}
              onClick={(e) => {
                handleEnd();
              }}></Button>

          </div>
          <div className="flex align-items-center">
            <div className="flex">
              Total: {totalRecords}
            </div>
          </div>
        </div>
      </div>

      <div className="col-12   md:col-4">
        <Button className='btn-type-5 w-full' onClick={(e) => {
          navigate(`/courses/${0}`);
        }}>Add Course</Button>
      </div>
    </div>
  );
}
