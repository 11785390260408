import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { Button } from 'primereact/button';

export default function HomePage() {
    const isLoggedIn = useSelector((state: RootState) => state.auth.user?.isLoggedIn);
    const navigate = useNavigate();
    return (
        <div>
            <h1 className="text-center" style={{ fontFamily: 'Montserrat' }}>
                Any language learning with Flashcards and AI-powered<br />
                exercise, generation, & feedback
            </h1>

            {!isLoggedIn && (
                <div className="text-center mt-4">
                    <Button className='btn-type-5' onClick={async (e) => {
                        await navigate(`/attempts`);
                    }}>Sign In to Get Started</Button>
                </div>
            )}
        </div>
    );
}
