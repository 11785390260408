import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axiosApi from '../axiosApi';


export interface UserDto {
  id: string;
  email: string;
  currentCourseId?: string;
  // other user properties
}

interface CourseDto {
  id: string;
  languageOriginal: string;
  languageToLearn: string;
  languagePair?: string;
}

interface UserSettingsState {
  userDto?: UserDto | null;
  courses: CourseDto[];
}

const initialState: UserSettingsState = {
  userDto: null,
  courses: [],
};

export const fetchUserSettings = createAsyncThunk('user/fetchUserSettings', async () => {
  const response = await axiosApi.get<UserDto>(`/api/users/GetMySettings`);
  return response.data;
});

export const fetchCourses = createAsyncThunk('user/fetchCourses', async () => {
  const response = await axiosApi.get(`/odata/Courses`);
  const courses = response.data.value.map((course: CourseDto) => ({
    ...course,
    languagePair: `${course.languageOriginal} -> ${course.languageToLearn}`,
  }));
  return courses;
});

export const saveUserSettings = createAsyncThunk('user/saveUserSettings', async (userDto: UserDto) => {
  await axiosApi.post(`/api/users/SaveMySettings`, userDto);
  // Optionally, return something or handle success message
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserSettings(state, action: PayloadAction<UserDto | undefined>) {
      state.userDto = action.payload;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserSettings.fulfilled, (state, action: PayloadAction<UserDto>) => {
        state.userDto = action.payload;
      })
      .addCase(fetchCourses.fulfilled, (state, action: PayloadAction<CourseDto[]>) => {
        state.courses = action.payload;
      });
  },
});

//export default userSlice;
