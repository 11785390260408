'use client';

import { useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import * as ws from './wordSlice';
import { useAppDispatch } from '../store';
import { Cn } from '../common/common';
import { useS } from '../common/barrel';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Calendar } from 'primereact/calendar';



export default function WordPage() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const calendarRef = useRef<Calendar>(null);




  const isNewWord = useS(state => state.word.word?.id == '0')
  const word = useS(state => state.word.word)
  const { id } = useParams<{ id: string }>();
  // if(word)
  //   word.nextReview = new Date(word.nextReview);

  const formatInterval = () => {
    if (!word) return '';
    return Cn.formatInterval(word.intervalMinutes ?? 0);
  }

  useEffect(() => {
    dispatch(ws.fetchWordById(id!));
    // Cleanup function to reset the word when component unmounts
    return () => {
      dispatch(ws.slice.actions.clearWord());
    };
  }, [dispatch, id]);

  return (
    <div className='flex justify-content-center'>
      <div className='grid fb-card'>
        <div className='flex col-12 justify-content-between align-items-center'>
          <div className="flex"></div>
          <div className="flex page-title">
            {isNewWord ? 'Add new word' : 'Edit word'}
          </div>
          <Button className="flex  btn-secondary p-button-text" type="button" icon="pi pi-trash"
            onClick={async (e) => {
              await dispatch(ws.removeWord(word!)).unwrap();
              await navigate(`/words`);
            }}
          ></Button>
        </div>
        <div className='col-12'>
          <label>Word to study</label><br />
          <input value={useS(s => s.word.word?.value || '')}
            className='w-full' autoCapitalize="off" autoComplete="off" autoCorrect="off"
            onChange={(e) => {
              dispatch(ws.slice.actions.setWord(word ? { ...word, value: e.target.value } : word));
            }}
          >
          </input>
        </div>
        <div className='col-12'>
          <label>Translation or Definition</label><br />
          <input value={useS(s => s.word.word?.translation || '')}
            className='w-full' autoCapitalize="off" autoComplete="off" autoCorrect="off"
            onChange={(e) => {
              dispatch(ws.slice.actions.setWord(word ? { ...word, translation: e.target.value } : word));
            }}
          >
          </input>
        </div>
        {!isNewWord && (
          <>
            <div className='col-12'>
              <label>Next Time to Review</label><br />
              <Calendar
                ref={calendarRef}
                value={word?.nextReview}
                showTime={true}
                onChange={(e) => {
                  dispatch(ws.slice.actions.setWord(word ? { ...word, nextReview: e.target.value } : word));
                }}
              //TODO: currently the calendar requires double click outside to get closed. 
              //This happens only with showTime option. I have no idea how to fix.
              // onBlur={(e) => {
              //   const calendarElement = calendarRef.current?.getElement();
              //   if (!e.relatedTarget || !calendarElement?.contains(e.relatedTarget)) {
              //     //setIsFocused(false);  // Calendar loses focus, now we can hide it
              //     calendarRef.current?.hide();
              //   }
              //   //calendarRef.current?.hide();
              // }}
              >
              </Calendar>
            </div>
            <div className="col-12">
              <label>Current Review Interval:</label>
              &nbsp;
              {formatInterval()}

            </div>
          </>
        )
        }
        <br />
        <br />
        <br />
        <div className="col-12">

          <Button className='w-full btn-primary' type='button'
            onClick={async (e) => {
              await dispatch(ws.saveWord(word!)).unwrap();
              await navigate(`/words`);
            }}>Save</Button>
        </div>

      </div>
    </div>
  );
}
