// store/wordSlice.ts
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axiosApi from '../axiosApi';
import { Word } from './wordListSlice';
import { Cn } from '../common/common';
import { v4 as uuidv4 } from 'uuid';

//TODO: use just Word
export interface WordState {
  word: Word | null;
}

const initialState: WordState = {
  word: null,
};

// Async thunk for fetching a single word by ID
export const fetchWordById = createAsyncThunk(
  'word/fetchWordById',
  async (id: string) => {

    if (id == '0') {
      let res = {} as Word;
      res.id = uuidv4().toString();
      return res;
    } else {

      const response = await axiosApi.get<Word>(`/api/words/${id}/GetById`);
      let dt = response.data.nextReview;
      if (dt) {
        // we need only to convert when receiving from server. When saving all works fine.
        const dt2 = Cn.utcToLocal(new Date(dt));
        response.data.nextReview = dt2;
      }
      return response.data;
    }

  }
);
export const saveWord = createAsyncThunk(
  'word/saveWord',
  async (word: Word) => {
    let toPost = { ...word }
    //toPost.nextReview = Cn.prepareDateToStore(word.nextReview);
    const response = await axiosApi.post(`/api/words`, toPost);
    return response.data;
  }
);
export const removeWord = createAsyncThunk(
  'word/removeWord',
  async (word: Word) => {
    const response = await axiosApi.delete(`/api/words/${word.id}`);
    return response.data;
  }
);

export const slice = createSlice({
  name: 'word',
  initialState,
  reducers: {
    clearWord: (state) => {
      state.word = null; // Clear the current word (useful when navigating away from the detail page)
    },
    setWord: (state: WordState, action: PayloadAction<Word | null>) => {
      state.word = action.payload; // Clear the current word (useful when navigating away from the detail page)
    },

    // TBD: possible generalization refactoring:
    // setState: <K extends keyof WordState>(state: WordState, action: PayloadAction<SetStatePayload<WordState>>) => {
    //   const { key, value } = action.payload;
    //   state[key] = value;
    // },
  },
  extraReducers: (builder) => {
    // builder.addCase(fetchWordById.pending, (state) => {
    //   state.loading = true;
    // });
    builder.addCase(fetchWordById.fulfilled, (state, action) => {
      state.word = action.payload;
    });
    // builder.addCase(fetchWordById.rejected, (state) => {
    //   state.loading = false;
    //   state.word = null;
    // });
  },
});

//export const { clearWord } = wordSlice.actions;

export const selectSingleWord = (state: RootState) => state.word.word;
export const selectIsNewWord = (state: RootState) => state.word.word?.id == '0';



export default slice;


