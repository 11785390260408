// store/wordsSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axiosApi from '../axiosApi';

export interface Word {
  id: string;
  value?: string;
  translation?: string;
  example?: string;
  notes?: string;
  createdDateTime?: Date;
  examinationFailed?: boolean;
  errorCount?: number;
  nextReview?: Date | null;
  intervalMinutes?: number;
  rank: number;
}

interface WordsState {
  searchWord: string;
  words: Word[];
  totalRecords: number;
  first: number;
  rows: number;
}

const initialState: WordsState = {
  searchWord: "",
  words: [],
  totalRecords: 0,
  first: 0,
  rows: 10,
};
interface TOdataCollection<T> {
  value: T[];
  "@odata.count": number;
}

// Async thunk for fetching words
export const fetchWords = createAsyncThunk(
  'words/fetchWords',
  async ({ first, rows, searchWord }: { first: number; rows: number; searchWord: string }) => {
    const response = await axiosApi.get(`/odata/Words`, {
      params: {
        $count: true,
        $orderby: 'Value',
        $skip: first,
        $top: rows,
        searchWord,
      },
    });
    return response.data;
  }
);

export const slice = createSlice({
  name: 'words',
  initialState,
  reducers: {
    // TODO: add generic reducer
    setFirst: (state, action) => {
      state.first = action.payload;
    },
    setRows: (state, action) => {
      state.rows = action.payload;
    },
    setSearchWord: (state, action) => {
      state.searchWord = action.payload;
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(fetchWords.pending, (state) => {
    //   state.loading = true;
    // });
    builder.addCase(fetchWords.fulfilled, (state, action) => {
      state.words = action.payload.value;
      state.totalRecords = action.payload["@odata.count"];
    });
    // builder.addCase(fetchWords.rejected, (state) => {
    //   state.loading = false;
    // });
  },
});

//export const { setFirst, setRows } = slice.actions;

export const selectWords = (state: RootState) => state.words.words;
export const selectTotalRecords = (state: RootState) => state.words.totalRecords;
export const selectFirst = (state: RootState) => state.words.first;
export const selectRows = (state: RootState) => state.words.rows;

export default slice;
