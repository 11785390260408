import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux'; // Assuming you have user state in Redux store
import axiosApi from '../axiosApi';
import { useS } from '../store';
import { Button } from 'primereact/button';

interface CourseDto {
    id: string;
    languageToLearn: string;
    languageOriginal: string;
    description: string;
    userId?: string;
}

const CoursePage: React.FC = () => {
    const [course, setCourse] = useState<CourseDto | null>(null);
    const { id } = useParams<{ id: string }>(); // To access route parameters
    const navigate = useNavigate();
    const currentUser = useS((state) => state.auth.user); // Assuming the user is stored in Redux

    useEffect(() => {
        const fetchCourse = async () => {
            if (id === '0') {
                const newCourse: CourseDto = {
                    id: uuidv4(),
                    languageToLearn: '',
                    languageOriginal: '',
                    description: ''
                };
                setCourse(newCourse);
            } else {
                const response = await axiosApi.get<CourseDto>(`/api/courses/${id}/GetById`);
                setCourse(response.data);
            }
        };

        fetchCourse();
    }, [id]);

    const handleSave = async () => {
        const updatedCourse = { ...course, userId: currentUser!.id, description: course!.description || '' };
        await axiosApi.post('/api/courses', updatedCourse);
        await navigate('/courses');
    };

    const handleRemove = async () => {
        await axiosApi.delete(`/api/courses/${course!.id}`);
        await navigate('/courses');
    };

    return (
        <div>
            {course && (
                <div className="grid">
                    <div className="flex col-12 justify-content-between">
                        <div className="flex"></div>
                        <div className="flex">Course</div>
                        <Button className="flex nopad p-button-text" onClick={handleRemove}>
                            <i className="pi pi-trash"></i>
                        </Button>
                    </div>
                    <div className="col-12">
                        <label>It is recommended to use English names of languages, because AI will understand you better and will give you the best responses</label>
                    </div>
                    <div className="col-12">
                        <label>Language to learn:</label>
                        <br />
                        <input
                            name="target"
                            value={course.languageToLearn}
                            onChange={(e) => setCourse({ ...course, languageToLearn: e.target.value })}
                        />
                    </div>
                    <div className="col-12">
                        <label>Original Language:</label>
                        <br />
                        <input
                            name="original"
                            value={course.languageOriginal}
                            onChange={(e) => setCourse({ ...course, languageOriginal: e.target.value })}
                        />
                    </div>
                    <div className="col-12">
                        <label>Label (optional):</label>
                        <br />
                        <input
                            name="description"
                            value={course.description}
                            onChange={(e) => setCourse({ ...course, description: e.target.value })}
                        />
                    </div>
                    <div className="col-12">
                        <Button className="btn-primary" onClick={handleSave}>
                            Save
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CoursePage;
