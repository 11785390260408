import { useAuthenticator, withAuthenticator } from '@aws-amplify/ui-react';
import { Amplify } from 'aws-amplify';
import config from './amplifyconfiguration.json';

import 'bootstrap/dist/css/bootstrap.min.css';
import '@aws-amplify/ui-react/styles.css';
import "primeflex/primeflex.css";
import "./globals.scss";
import { BrowserRouter as Router, Route, Routes, Outlet, Navigate } from "react-router-dom";

import * as authSlice from "./auth/authSlice";
import { Provider } from 'react-redux';
import { useEffect } from "react";
import store, { useAppDispatch } from "./store";
import HomePage from "./home/Home";
import NavMenu from "./NavMenu";
import WordsPage from "./words/WordListPage";
import LoginPage from "./login/page";
import WordPage from './words/WordPage';
import FlashcardsPage from './words/FlashcardPage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import UserSettingsPage from './settings/UserSettingsPage';
import CourseListPage from './settings/CourseListPage';
import CoursePage from './settings/CoursePage';
import AttemptPage from './attempts/AttemptPage';
import AttemptListPage from './attempts/AttemptListPage';
import { Hub } from 'aws-amplify/utils';
import { Authenticator } from '@aws-amplify/ui-react';


Amplify.configure(config);

// Add this new import

function AuthenticatedContent({ children }: Readonly<{
  children: React.ReactNode;
}>) {
  return (
    <div className='all-except-footer'>
      {<NavMenu />}
      <main className='container'>{children}</main>
      <noscript>Please enable JavaScript to continue using this application.</noscript>
    </div>
  );
}

// Add this new component for protected routes
const ProtectedRoute = () => {
  const { user } = useAuthenticator((context) => [context.user]);

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
};

// Need separate component because of error:Error: could not find react-redux context value; please ensure the component is wrapped in a <Provider>
const FetchAuthSession = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(authSlice.fetch1());
  }, [dispatch]);

  return <>{children}</>;
};

// Move this component outside of RootLayout
const AuthEventHandler = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Subscribe to auth events
    const unsubscribe = Hub.listen('auth', handleAuthEvents);

    // Cleanup function to unsubscribe
    return () => unsubscribe();
  }, []);

  const handleAuthEvents = (data: any) => {
    switch (data.payload.event) {
      case 'signedIn':
        console.log('Successfully signed in');
        dispatch(authSlice.fetch1());
        break;
      case 'signedUp':
        console.log('Successfully signed up');
        dispatch(authSlice.fetch1());
        break;
      case 'signedOut':
        console.log('Signed out');
        dispatch(authSlice.fetch1());
        break;
    }
  };

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', handleAuthEvents);
    return () => unsubscribe();
  }, [handleAuthEvents]);

  return null;
};

function RootLayout1() {
  const isMobile = window.innerWidth < 768;

  return (
    <Provider store={store}>
      <Authenticator.Provider>
        <div className='top-progress-bar'>
          <div className='progress indeterminate'></div>
        </div>
        <AuthEventHandler />
        <FetchAuthSession>
          <Router>
            <div className='all-except-footer'>
              <NavMenu />
              <main className='container'>
                <Routes>
                  {/* Public route for HomePage */}
                  <Route path="/" element={<HomePage />} />
                  <Route path="/login" element={<LoginPage />} />

                  {/* Protected routes */}
                  <Route element={<ProtectedRoute />}>
                    <Route path="/words" element={<WordsPage />} />
                    <Route path="/words/:id" element={<WordPage />} />
                    <Route path="/words/learn" element={<FlashcardsPage />} />
                    <Route path="/users/my-settings" element={<UserSettingsPage />} />
                    <Route path="/courses" element={<CourseListPage />} />
                    <Route path="/courses/:id" element={<CoursePage />} />
                    <Route path="/attempts/:id" element={<AttemptPage />} />
                    <Route path="/attempts" element={<AttemptListPage />} />
                  </Route>
                </Routes>
              </main>
              <noscript>Please enable JavaScript to continue using this application.</noscript>
            </div>
            <footer className="text-color-2" style={{ fontSize: '12px', textAlign: 'center', padding: '1em', width: '100%' }}>
              <span>&copy; 2024 Aleksandr Khromov. <a href="https://www.reddit.com/r/PhraseMaker/" target="_blank">Give Feedback</a></span>
            </footer>
          </Router>
        </FetchAuthSession>
        <ToastContainer
          position={isMobile ? "bottom-center" : "top-right"}
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='colored'
        />
      </Authenticator.Provider>
    </Provider>
  );
}

//const RootLayout = observer(RootLayout1);
const RootLayout = RootLayout1;

export default RootLayout;

