'use client';

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as fsc from './flashcardSlice'; // Adjust the path according to your structure
import { useAppDispatch, useS } from '../store';
import { Button } from 'primereact/button';
import { Cn } from '../common/common';


export default function FlashcardPage() {

  const dispatch = useAppDispatch();

  let [turned, setTurned] = useState(false);

  const navigate = useNavigate();

  const wss = useS((state) => state.flashcard);

  useEffect(() => {
    dispatch(fsc.fetchNextFlashcard()); // Fetch the next flashcard on component mount
  }, [dispatch]);

  const handleKnow = () => {
    dispatch(fsc.markAsKnown(wss!.wordWithInfo!.word!.id));
  };

  const handleForgot = () => {
    dispatch(fsc.markAsForgotten(wss!.wordWithInfo!.word!.id));

  };

  const handleEdit = () => {
    navigate(`/words/${wss!.wordWithInfo!.word!.id}`);
  };

  const turnCard = () => {
    setTurned(!turned);
  };

  const goToPractice = () => {
    navigate(`/attempts/0?wordId=${wss!.wordWithInfo!.word!.id}`);
  };


  return (
    <div style={{
      display: 'flex', justifyContent: 'center', alignItems: 'center'
    }}>
      {
        !wss.wordWithInfo?.word && <div>
          All Learned. Nothing to learn right now. Please add new words or wait for some days.
        </div>
      }
      {
        wss.wordWithInfo?.word &&
        <div className="grid justify-content-center  w-full">

          <div className="grid col-12 nopad" style={{ width: '320px' }}>
            <div className="col-12 flex justify-content-between align-items-center">
              <label className='flex'>To review: {wss!.wordWithInfo?.remainsCount}
              </label>
              <Button className='z-1  btn-secondary p-button-text' type="button" icon="pi pi-pencil"
                onClick={(e) => {
                  handleEdit();
                }}
              ></Button>
            </div>
            <div className="col-12 flex justify-content-left">
              <Button className="w-full btn-type-4 z-1" onClick={() =>
                goToPractice()
              }>Practice with AI</Button>
            </div>
            <div className="col-12 flex justify-content-center light-behind">
              {/* <div class="flashcard1"  (click)="onTurn()" [ngClass]="isTurned?'flashcard-1':'flashcard-2'" > */}
              <div className={`flashcard1 ${turned ? 'flashcard-1' : 'flashcard-2'}`} onClick={() => {
                turnCard();
              }}>
                <div style={{ fontSize: '24px', padding: '50px 0' }}>
                  {turned ? wss.wordWithInfo.word.translation : wss.wordWithInfo.word.value}
                </div>

              </div>
            </div>
            <div className="col-12 flex justify-content-center">
              <Button className="w-full btn-type-3 z-1"
                onClick={() => {
                  handleKnow();
                }}
              >Know</Button>
            </div>
            <div className="col-12 flex justify-content-center">
              <Button className="w-full btn-type-3 z-1"
                onClick={() => {
                  handleForgot();
                }}
              >Forgot</Button>
            </div>
            <div className="col-12 flex justify-content-start">
              <label className="flex"><i>The word suggested for review after: {Cn.formatInterval(wss.wordWithInfo.word.intervalMinutes || 0)}</i></label>
            </div>
          </div>

        </div>
      }
    </div>
  );
}
