
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Auth from 'aws-amplify/auth';
import { RootState } from "../store";
import { UserDto } from "../settings/userSlice";
import * as userSlice from '../settings/userSlice'


export enum AuthStatus {
    Loading,
    Error,
    Success,
    Initial,
}

export interface UserProps extends UserDto {
    isLoggedIn: boolean;
    //   username: string;
    //   token: string;
}

export interface StateProps {
    user: UserProps | null;
    status: AuthStatus;
    message: string | unknown;
}


// initalize state
const initalState: StateProps = {
    user: null,
    status: AuthStatus.Initial,
    message: "",
};

// authslice
const authSlice = createSlice({
    name: "auth",
    initialState: initalState,
    reducers: {
        reset: (state) => {
            state.status = AuthStatus.Initial;
            state.user = null;
            state.message = "";

        },
    },

    extraReducers: (builder) => {
        builder.addCase(fetch1.pending, (state, action) => {
            state.status = AuthStatus.Loading;
        });
        builder.addCase(fetch1.fulfilled, (state, action) => {
            state.status = AuthStatus.Success;
            const dto = action.payload;
            //let email = action.payload.tokens?.idToken?.payload?.email as string;
            state.user = dto ? { ...dto, isLoggedIn: !!dto.email } : null; //{ email: email, isLoggedIn: !!email, };
        });
        builder.addCase(fetch1.rejected, (state, action) => {
            state.status = AuthStatus.Error;
            state.message = action.payload;
        });

    },
});
export const fetch1 = createAsyncThunk<UserDto | null, void, { rejectValue: string }>(
    "auth/fetch1",
    async (user: any, thunkAPI) => {
        //const dispatch = useAppDispatch();
        try {
            const amplifySession = await Auth.fetchAuthSession();

            let email = amplifySession.tokens?.idToken?.payload?.email as string;
            if (!email) {
                return null;
            }

            //Magic happens on the server. It gets the email from Amazon cognito and if user does not exist yet in our database - adds it.
            const userDto = thunkAPI.dispatch(userSlice.fetchUserSettings()).unwrap();
            return userDto;
        } catch (error: any) {
            // console.log(error);
            if (error.message) {
                const message: string = error.message;
                return thunkAPI.rejectWithValue(message);
            } else {
                return thunkAPI.rejectWithValue("Error fetching user attributes");
            }
        }
    }
);

export const selectUserState = (state: RootState) => state.auth;


export const { reset } = authSlice.actions;
export default authSlice;//.reducer;
