import axios from 'axios';
import * as Auth from 'aws-amplify/auth';
import * as _ from "lodash";
import { toast } from 'react-toastify';
import { BaseQueryFn } from '@reduxjs/toolkit/query';

// Create an Axios instance
const axiosApi = axios.create({});

// Add a request interceptor to include the JWT token in headers
axiosApi.interceptors.request.use(
  async (config) => {
    try {
      const session = await Auth.fetchAuthSession();
      const idToken = session?.tokens?.idToken?.toString();

      // If the token exists, add it to the request headers
      if (idToken) {
        config.headers.Authorization = `Bearer ${idToken}`;
      } else {
        // Handle cases where the user is not authenticated
        console.warn('No ID token found, user might not be authenticated');
      }
    } catch (error) {
      console.error('Error fetching the current session:', error);
      // Handle the error as necessary (e.g., redirecting to login page)
    }
    return config;
  },
  (error) => {
    // Handle any errors in the request setup
    return Promise.reject(error);
  }
);
let totalRequests = 0;

const showProgress = () => {
  document.querySelector(".top-progress-bar div")?.classList.add("in-progress");
}
const hideProgress = () => {
  let body = document.querySelector(".top-progress-bar div");
  body?.classList.remove("in-progress");
}

const disableInProgress = _.debounce(() => {
  if (totalRequests === 0) {
    hideProgress();
  }
}, 200);

let activeRequests = 0;

let decreaseRequests = () => {
  activeRequests--;
  disableInProgress();
}

axiosApi.interceptors.request.use(
  function (config) {
    // Increment the counter for each new request
    activeRequests += 1;
    //logRequestsCounter();
    showProgress();//show unconditionally 
    return config;
  },
  function (error) {
    decreaseRequests();
    return Promise.reject(error);
  }
);
// Add a response interceptor to hide the loading spinner
axiosApi.interceptors.response.use(
  (response) => {
    decreaseRequests();
    return response;
  },
  (error) => {
    decreaseRequests();
    const url = error.config?.url;
    const errorMessage = error.response?.statusText || 'An unexpected error occurred';
    if (url) {
      //const errorMessage = error.response?.data?.message || 'An unexpected error occurred';
      toast.error(`Request to\n ${url} failed with error message:\n ${errorMessage}`);
    } else {
      toast.error(`Http request failed with error message:\n ${errorMessage}`);
    }

    //return Promise.resolve(error.response);
    return Promise.reject(error);
  }
);



export default axiosApi;

