import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import RootLayout from './App';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback, logErrorToService } from './ErrorFallback';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <ErrorBoundary
  //   FallbackComponent={ErrorFallback}
  //   onError={logErrorToService}  // Optional error logging function
  //   onReset={() => {
  //     // Reset any application state when the error boundary is reset
  //     window.location.reload();  // Example action to reload the page
  //   }}
  // >
  <React.StrictMode>
    <RootLayout />
  </React.StrictMode>
  // </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
