import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../store'; // Assuming you have a root reducer setup
import * as uss from './userSlice'
import { useNavigate } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

const UserSettingsPage: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const userDto = useSelector((state: RootState) => state.userSettings.userDto);
    const courses = useSelector((state: RootState) => state.userSettings.courses);

    useEffect(() => {
        dispatch(uss.fetchUserSettings());
        dispatch(uss.fetchCourses());
    }, [dispatch]);

    const handleSave = async () => {
        if (userDto) {
            await dispatch(uss.saveUserSettings(userDto));
            //alert('Saved');
        }
    };

    const handleManageCourses = () => {
        navigate('/courses');
    };

    const handleAddNewCourse = () => {
        navigate(`/courses/0`);
    };
    const handleCourseChange = (courseId: string) => {
        const dto = {
            ...userDto,
            currentCourseId: courseId
        } as uss.UserDto
        dispatch(uss.userSlice.actions.setUserSettings(dto));
    };

    return (
        <div>
            {userDto && (
                <div className="grid">
                    <div className="col-12">
                        <div style={{ fontSize: '32px' }}>Settings</div>
                    </div>
                    {!userDto.currentCourseId && (
                        <div className="col-12">
                            * Please add a course and then select it as current
                        </div>
                    )}
                    <div className="col-12">

                        <Dropdown
                            value={userDto.currentCourseId || ''}
                            onChange={(e) =>
                                handleCourseChange(e.target.value)
                            }
                            options={courses}
                            optionLabel="languagePair"
                            optionValue="id"
                            placeholder="Set your current course"
                            className="fill-parent" />

                        {/* <select
                            id="course"
                            name="course"
                            value={userDto.currentCourseId || ''}
                            onChange={(e) => (userDto.currentCourseId = e.target.value)}
                            className="fill-parent"
                        >
                            <option value="">Set your current course</option>
                            {courses.map((course) => (
                                <option key={course.id} value={course.id}>
                                    {course.languagePair}
                                </option>
                            ))}
                        </select> */}
                    </div>
                    <div className="col-12">
                        <Button className="btn-primary" onClick={handleManageCourses}>
                            Add/Edit your Courses
                        </Button>
                    </div>
                    <div className="col-12">
                        <Button className="btn-primary" onClick={handleSave}>
                            Save
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserSettingsPage;
