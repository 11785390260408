'use client';

import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import './nav-menu.scss'
import { useSelector } from 'react-redux';
import { selectUserState } from './auth/authSlice';
import { RootState } from './store';
//import 'bootstrap/dist/css/bootstrap.min.css';

const NavMenu: React.FC = () => {
    const [isExpanded, setIsExpanded] = useState(false);
    const navbarRef = useRef<HTMLElement>(null);
    const isLoggedIn = useSelector((state: RootState) => state.auth.user?.isLoggedIn);
    // const navbarRef = useRef(null);
    // const navbarTogglerRef = useRef(null);

    const toggleNavbar = () => {
        setIsExpanded(!isExpanded);
    };
    useEffect(() => {
        const handleDocumentClick = (event: Event) => {
            if (!navbarRef.current) return;
            const navbar = navbarRef.current.querySelector('.navbar');
            const navbarToggler = navbarRef.current.querySelector('.navbar-toggler');
            const isToggleClick = navbarToggler?.contains(event.target as Node);
            // I handle button click here to keep it in sync with the collapse case handling.
            if (isToggleClick) {
                toggleNavbar();
                return;
            }
            //const isClickInside = navbar.contains(event.target);// || navbarToggler.contains(event.target);
            if (isExpanded) {
                toggleNavbar();
            }
        };

        // Add the event listener
        document.addEventListener('click', handleDocumentClick);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [isExpanded]);

    return (
        <header ref={navbarRef}>
            <nav
                className="navbar navbar-expand-sm navbar-toggleable-sm navbar-light border-bottom box-shadow mb-3"
            >
                <div className="container justify-content-between">
                    <div className="flex navbar-toggler-wrap align-items-center" style={{ width: "100%" }}>
                        <button
                            className="navbar-toggler flex"
                            type="button"
                            data-toggle="collapse"
                            data-target=".navbar-collapse"
                            aria-label="Toggle navigation"
                        >
                            <i className="pi pi-bars"></i>
                        </button>
                        <div className="nav-item center-align   inline-links caption-mobile  flex"             >
                            <Link to="/" className="nav-link text-dark">phrase <img src="/icons/header-icon.png" style={{ height: "28px" }} /> maker

                            </Link>
                        </div>
                        <div className="flex"></div>
                    </div>

                    <div
                        className={`${isExpanded ? "show" : ""} navbar-collapse collapse d-sm-inline-flex justify-content-end`}
                    >
                        <div className="navbar-nav flex-grow inline-links"
                            style={{ justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                            <div className="nav-item  left-align   inline-links" >
                                {isLoggedIn &&
                                    <Link to='/words' className="nav-link text-dark"
                                    >Words</Link>
                                }
                                {isLoggedIn &&
                                    <Link to='/words/learn' className="nav-link text-dark"
                                    >Flashcards</Link>
                                }
                            </div>
                            <div className="nav-item center-align   inline-links caption-in-menu"
                            >
                                <Link to='/' className="nav-link text-dark"
                                >phrase <img src="/icons/header-icon.png" style={{ height: "28px" }} /> maker</Link>
                            </div>
                            <div className="nav-item right-align  inline-links">
                                {isLoggedIn &&
                                    <Link to='/users/my-settings' className="nav-link text-dark"
                                    >Settings</Link>
                                }
                                <Link to='/login' className="nav-link text-dark"
                                >{isLoggedIn ? 'Sign Out' : 'Sign In'}</Link>
                            </div>
                        </div>
                    </div>

                </div>
            </nav>
        </header>

    );
};

export default NavMenu;