'use client';

import * as Auth from 'aws-amplify/auth';
import { useEffect, useState } from 'react';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { Button } from 'primereact/button';
import * as userSlice from '../auth/authSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { Navigate } from 'react-router-dom';


export default function LoginPage() {
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const userInfo = useSelector((state: RootState) => state.userSettings?.userDto);

  if (!user) {
    return (
      <div className="login-container" style={{ paddingTop: '100px' }}>
        {/* <h1>Welcome to Our App</h1> */}
        {/* <p>Please sign in to continue</p> */}
        <Authenticator>
          {({ signOut, user }) => (
            <Navigate to="/" replace />
          )}
        </Authenticator>
      </div>
    );
  }

  return (
    <>
      {/* <h1>Welcome, {user.username}!</h1> */}
      {<p>Email: {userInfo?.email}</p>}
      <Button className='btn-primary' onClick={signOut}>Sign Out</Button>
    </>
  );
}
