// features/attemptSlice.ts
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { Word } from './wordListSlice';
import axiosApi from '../axiosApi';

interface WordWithInfo {
  word: Word | null;
  remainsCount: number;
}
export interface WordWithInfoState {
  wordWithInfo: WordWithInfo | null;
}

const initialState: WordWithInfoState = {
  wordWithInfo: null
};

// Thunks without try/catch or rejectWithValue, as errors are handled globally
export const fetchNextFlashcard = createAsyncThunk(
  'flashcard/fetchNextFlashcard',
  async () => {
    const response = await axiosApi.get('/api/words1/GetNextFlashcard');
    return response.data as WordWithInfo;
  }
);

export const markAsKnown = createAsyncThunk(
  'flashcard/markAsKnown',
  async (id: string, { dispatch }) => {
    await axiosApi.post(`/api/words1/${id}/know`);
    dispatch(fetchNextFlashcard());
  }
);

export const markAsForgotten = createAsyncThunk(
  'flashcard/markAsForgotten',
  async (id: string, { dispatch }) => {
    await axiosApi.post(`/api/words1/${id}/forgot`);
    dispatch(fetchNextFlashcard());
  }
);

export const flashcardSlice = createSlice({
  name: 'flashcard',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNextFlashcard.fulfilled, (state, action) => {
        state.wordWithInfo = action.payload;
      })
      .addCase(markAsKnown.fulfilled, (state, action) => {
      })
      .addCase(markAsForgotten.fulfilled, (state, action) => {
      })
  },
});

export default flashcardSlice;
