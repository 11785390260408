import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth/authSlice";
import { slice as wordsSlice } from './words/wordListSlice';
import { slice as wordSlice } from './words/wordSlice';
import * as courseListSlice from './settings/courseListSlice';
import * as flashcardSlice from './words/flashcardSlice';
import * as userSettingsSlice from './settings/userSlice';

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
// import audioPlayer from "./player/currentAudioPlayer";
// import homePageSlice from "./homePage/homePageSlice";

const store = configureStore({

  reducer: {
    auth: authSlice.reducer,
    words: wordsSlice.reducer,
    word: wordSlice.reducer,
    //wordApi: wordApi.reducer,
    flashcard: flashcardSlice.flashcardSlice.reducer,
    userSettings: userSettingsSlice.userSlice.reducer,
    courses: courseListSlice.slice.reducer,
    //player: audioPlayer,
    //homePage: homePageSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })//.concat(wordApi.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export function useS<T>(selector: (state: RootState) => T): T {
  return useAppSelector(selector);
}

export default store;

