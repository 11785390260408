// store/wordsSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../store';
import axiosApi from '../axiosApi';

export interface CourseDto {
  id: string;
  userId: string;
  languageToLearn: string;
  languageOriginal: string;
  description: string;
}

interface CourseListState {
  items: CourseDto[];
  totalRecords: number;
  first: number;
  rows: number;
}

const initialState: CourseListState = {
  items: [],
  totalRecords: 0,
  first: 0,
  rows: 10,
};
interface TOdataCollection<T> {
  value: T[];
  "@odata.count": number;
}

// Async thunk for fetching words
export const fetchItems = createAsyncThunk(
  'words/fetchItems',
  async ({ first, rows }: { first: number; rows: number }) => {
    const response = await axiosApi.get(`/odata/Courses`, {
      params: {
        $count: true,
        $orderby: 'LanguageToLearn',
        $skip: first,
        $top: rows
      },
    });
    return response.data;
  }
);

export const slice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    // TODO: add generic reducer
    setFirst: (state, action) => {
      state.first = action.payload;
    },
    setRows: (state, action) => {
      state.rows = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchItems.fulfilled, (state, action) => {
      state.items = action.payload.value;
      state.totalRecords = action.payload["@odata.count"];
    });
  },
});

//export const { setFirst, setRows } = slice.actions;

export const selectCourses = (state: RootState) => state.courses.items;
export const selectTotalRecords = (state: RootState) => state.courses.totalRecords;
export const selectFirst = (state: RootState) => state.courses.first;
export const selectRows = (state: RootState) => state.courses.rows;

export default slice;
