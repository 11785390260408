'use client';

import { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, useAppDispatch } from '../store';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useNavigate } from 'react-router-dom';
import axiosApi from '../axiosApi';
import { AttemptDto } from './AttemptDto';


export default function AttemptListPage() {

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [attempts, setAttempts] = useState<AttemptDto[] | undefined>();
  const [searchWord, setSearchWord] = useState<string>('');
  const [searchWordTranslation, setSearchWordTranslation] = useState<string>('');
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [first, setFirst] = useState<number>(0);
  const [rows, setRows] = useState<number>(10);

  const fetchAttempts = async () => {
    const response = await axiosApi.get(`/odata/attempts`, {
      params: {
        $count: true,
        $orderby: 'Sentence',
        $skip: first,
        $top: rows,
        searchWord,
        searchWordTranslation,
      },
    });
    setAttempts(response.data.value);
    setTotalRecords(response.data['@odata.count']);
  };

  useEffect(() => {
    fetchAttempts();
  }, [first]);

  const handleNext = () => {
    if (isLastPage()) return;
    setFirst(first + rows);
  };

  const handlePrev = () => {
    if (first > 0) {
      setFirst(first - rows);
    }
  };

  const handleStart = () => {
    setFirst(0);
  };

  const handleEnd = () => {
    setFirst(Math.floor(totalRecords / rows) * rows);
  };

  const isLastPage = () => {
    return (first + rows) > totalRecords;
  };

  const isFirstPage = () => {
    return first === 0;
  };

  const onPageChange = (event: any) => {
    fetchAttempts();
  };

  const onEdit = async (id: string) => {
    await navigate(`/attempts/${id}`);
  };


  return (
    <div className='grid'>
      <div className="grid col-12 w-full flex align-items-center justify-content-between">
        <div className="col-12 md:col-6 flex justify-content-start">
          <div className="flex">
            <span style={{ fontSize: '32px' }}>Attempts</span>
          </div>
        </div>
        <div className="col-12 md:col-6 flex justify-content-end">
          <div className="flex-container-1">
            <div className="flex flex-container-2">
              <input className="w-full" name="search" type="search" onChange={(e) => {
                setSearchWord(e.target.value);
              }} placeholder="Search" />
            </div>
            <Button className="btn-type-5" icon="pi pi-search" onClick={(e) => {
              fetchAttempts();
            }} label=""></Button>
            <Button className="btn-type-5" icon="pi pi-plus" onClick={(e) => {
              onEdit('0')
            }} label=""></Button>
          </div>
        </div>
      </div>

      <div className="col-12 ">
        <div className="table-wrap no-header">
          <DataTable value={attempts} dataKey="id" paginator={false} rows={10} lazy={true}

            first={first} onPage={onPageChange}
            totalRecords={totalRecords}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            emptyMessage="Nothing to show yet. Please do some practice before."
          >
            <Column field="attemt" body={(attemt) => (
              <div onClick={() => onEdit(attemt.id)} style={{ cursor: 'pointer', width: '100%', height: '100%' }}>
                {attemt.sentence}
              </div>
            )} />
          </DataTable>
        </div>
        <div className="flex col-12 justify-content-between">
          <div className="flex">
          </div>
          <div className="flex">
            <Button className='flex p-button-text' type='button' icon="pi pi-angle-double-left" text disabled={isFirstPage()}
              onClick={(e) => {
                handleStart();
              }}></Button>
            <Button className='flex p-button-text' type='button' icon="pi pi-chevron-left" disabled={isFirstPage()}
              onClick={(e) => {
                handlePrev();
              }}></Button>
            <Button className='flex p-button-text' type='button' icon="pi pi-chevron-right" disabled={isLastPage()}
              onClick={(e) => {
                handleNext();
              }}></Button>
            <Button className='flex p-button-text' type='button' icon="pi pi-angle-double-right" disabled={isLastPage()}
              onClick={(e) => {
                handleEnd();
              }}></Button>

          </div>
          <div className="flex align-items-center">
            <div className="flex">
              Total: {totalRecords}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
