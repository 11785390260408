
import * as moment from "moment";

export class Cn {
  static formatInterval(v: number) {
    let totalMinutes = v ?? 0;
    let duration = moment.duration(totalMinutes, 'minutes');
    let parts = [[duration.years(), 'years'], [duration.months(), 'months'], [duration.days(), 'days'], [duration.hours(), 'hours'], [duration.minutes(), 'minutes']];
    let nonZeroInd = parts.findIndex(i => {
      return !!i[0];
    });
    let parts1 = parts.splice(nonZeroInd);
    let parts2 = parts1.map(i => {
      return `${i[0]} ${i[1]}`;
    })
    let res = parts2.join(' - ');
    return res;
  }
  static isAcDebug() {
    return localStorage.getItem("isAcDebug") == "true";
  }

  /*1. Together with prepareDateToShow these 2 functions allow to work with date in Timezone agnostic way. Rationale is below.
    * Being in +3GMT zone I select on holidays calendar 19. I get in my local database: Okt 2019-10-18 21:00:00.0000000 +00:00. That is 19 Okt 00:00 GMT+3.
    * If we use that in London we get wrong Holiday: 18 Okt. To fix this Dates need to be Timezone agnostic.
    * Unit test steps:
    * Input flow: Take only date. Make GMT from that. Should have 19 Okt 00:00 +OGMT Store to DB.
    * Output flow: Take from DB. Convert GMT to local. We should get 18 Okt 21:00 GMT+0 here again (or 19 Okt 00:00 GMT+3). And 19 showing on calendar in our TZ.
    * Acceptance test case: set holiday using browser in GMT+3. Inititial result: You will see the holiday in London one day shifted back. Desired result: You should see in London's browser the same day.
    * 2. This functions should be used everywhere when you work with Dates only.
    * 3. TODO: Think of how to wrap it into custom calendar control. This control should also assert if we feed non 00:00 GMT+0 dates to it.
    */

  /* Another explanation of what we do here. We pretend that the date was selected in the browser beeing in GMT timezone. I mean physically...
   */
  static prepareDateToStore(date: any) {
    if (!date) return date;
    const res = date ? new Date(date.getTime() - date.getTimezoneOffset() * 60000) : date;
    return res;
  }

  // static prepareDateToStore_old(date: any) {
  //    if (!date) return null;
  //    let s1 = moment(date).format(this.dateFormat);
  //    let res = moment.utc(s1, this.dateFormat);
  //    return res.toDate();
  // }
  static prepareDateToShow(date: any) {
    if (!date) return date;
    const res = date ? new Date(date.getTime() + date.getTimezoneOffset() * 60000) : date;
    return res;
  }

  // It seems that there is no automatic conversion from UTC to local time. So, we make it manually using this function.
  static utcToLocal(utcDate: Date) {
    if (!utcDate) return utcDate;
    const localDate = new Date(utcDate.getTime() - (utcDate.getTimezoneOffset() * 60000));
    return localDate
  }
}